import PropTypes from 'prop-types';
import { Col, Form, Row, Select } from 'antd';
import { useContext, useState, useEffect, useMemo, useCallback } from 'react';
import useCheckMobileScreen from '../../../../../../../hooks/useCheckMobileScreen';
import { userInformations } from '../../../../../../../contex';
import { getListService } from '../../../../../../../logic/refbook/service/ServiceApiFunction';

const { Option } = Select;

const TemplateOperationServicesModalForm = ({
  form,
  editingOperation,
  isEdtiting,
  operations,
}) => {
  const { userInfo } = useContext(userInformations);
  const isMobile = useCheckMobileScreen();

  const filteredOperationsList = useMemo(() => {
    if (isEdtiting) return [editingOperation];
    return operations.filter((item) => item.service === null);
  }, [operations, isEdtiting]);

  // Выбираем первую операцию в списке по умолчанию
  useEffect(() => {
    if (filteredOperationsList?.length > 0) {
      form.setFieldsValue({
        operation: filteredOperationsList[0].id,
      });
    }
    // Если операция редактируется, то устанавливаем значение услуги
    if (isEdtiting) {
      setServicesOptions(
        editingOperation?.service ? [editingOperation.service] : [],
      );
      form.setFieldsValue({
        service: editingOperation?.service?.id,
      });
    }
  }, [filteredOperationsList, form, isEdtiting]);

  const [servicesOptions, setServicesOptions] = useState([]);
  const handleGetServicesList = useCallback(
    async (keyword = null) => {
      const res = await getListService(userInfo, keyword);
      if (res?.data) {
        setServicesOptions(res.data);
      }
    },
    [setServicesOptions],
  );

  return (
    <>
      <Form form={form} layout={'vertical'}>
        <Row gutter={16}>
          <Col xs={24} lg={24}>
            <Form.Item
              name={'operation'}
              label={'Операция'}
              disabled={isEdtiting}
              rules={[{ required: true, message: 'Обязательное поле!' }]}
            >
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
                showSearch
                disabled={isEdtiting}
                placeholder="Поиск операции"
              >
                {filteredOperationsList && filteredOperationsList.length > 0 ? (
                  filteredOperationsList.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.operation.operation.name}
                    </Option>
                  ))
                ) : (
                  <Option disabled value="Загрузка">
                    Загрузка
                  </Option>
                )}
              </Select>
            </Form.Item>

            <Form.Item
              name={'service'}
              label={'Услуга'}
              rules={[{ required: true, message: 'Обязательное поле!' }]}
            >
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
                showSearch
                filterOption={false}
                onSearch={async (value) => await handleGetServicesList(value)}
                placeholder="Найти услугу"
              >
                {servicesOptions.length > 0 &&
                  servicesOptions.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

TemplateOperationServicesModalForm.propTypes = {
  form: PropTypes.any.isRequired,
  operations: PropTypes.array,
};
export default TemplateOperationServicesModalForm;
