import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Pagination,
  Row,
  Select,
  Spin,
  Tabs,
} from 'antd';
import { restApiHost } from '../utils/appVariables';
import RefBookTable from '../components/refbook/RefBookTable';
import { userInformations } from '../contex';
import { call } from '../apiUtils/call';
import RefBookContractModal from '../components/refbook/refbookConctract/RefBookContractModal';
import RefBookPresentationsModal from '../components/refbook/refbookPresentations/RefBookPresentationsModal';
import { ErrorBoundary } from '../components/ErrorBoundary';
import RefBookCPModal from '../components/refbook/refbookCP/RefBookCPModal';
import { getBranchId } from '../localStorageUtils/getBranchId';
import TextArea from 'antd/es/input/TextArea';
import { useTypedSelector } from '../store/hooks/useTypedSelector';
import formationDocumentTitle from '../utils/formationDocumentTitle';
import useAsyncEffect from 'use-async-effect';
import useCheckMobileScreen from '../hooks/useCheckMobileScreen';
import CitesModal from '../components/refbook/citesModal/CitesModal';
import RefbookTaskTemplateModal from '../components/refbook/taskTemplate/RefbookTaskTemplateModal';

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const Refbook = () => {
  const { userInfo } = useContext(userInformations);
  const [refBookOptions, setRefBookOptions] = useState([]);
  const [data, setData] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [total, setTotal] = useState(0);
  const [ralewayOptions, setRalewayOptions] = useState([]);
  const [totalRaleWay, setTotalRaleway] = useState(0);
  const [loadingRaleWay, setLoadingRaleway] = useState(false);
  const currentPage = useRef(1);
  const [isWaypointPage, setIsWaypointPage] = useState(false);
  const searchKeyword = useRef(null);
  const [searchValueForInput, setSearchValueForInput] = useState('');
  const [selectFilterActivityValue, setSelectFilterActivityValue] =
    useState('true');
  const [pageForPaginationInSelect, setPageForPaginationInSelect] = useState(1);
  const [activeTabBook, setActiveTabBook] = useState('transport_units');
  const [contactPlaceholders, setContactPlaceholders] = useState([]);
  //modal
  const [deleteItem, setDeleteItem] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [visibleModal, setVisible] = useState(false);
  const [form] = Form.useForm();
  const notificationCount = useTypedSelector(
    (state) => state.notificationCount.count,
  );
  const isMobile = useCheckMobileScreen();

  useAsyncEffect(async () => {
    await getWaypoints();
  }, []);

  useAsyncEffect(async () => {
    if (activeTabBook === 'contract_template') {
      await getContractPlaceholders();
    }
  }, [activeTabBook]);

  useEffect(() => {
    if (selectedOption) {
      if (selectedOption.includes('refbook/route_waypoint')) {
        setIsWaypointPage(true);
      } else {
        setIsWaypointPage(false);
      }
    }
  }, [selectedOption]);

  useEffect(() => {
    searchKeyword.current = searchValueForInput;
  }, [searchValueForInput]);

  useEffect(async () => {
    if (selectedOption) {
      const url = `${restApiHost}${selectedOption}`;
      currentPage.current = 1;
      await getOptions(url);
      await getData(url, 1);
    }
  }, [selectFilterActivityValue]);

  const getUserBranch = (userInfo) => {
    if (!userInfo) return null;
    if (userInfo.haveViewBranch) {
      return getBranchId();
    }
    return userInfo.branch?.id;
  };

  const getRalewayOptions = async (page = 1) => {
    setLoadingRaleway(true);
    try {
      const json = await call(
        `${restApiHost}/refbook/railway/?page=${page}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      setRalewayOptions(json?.data);
      setTotalRaleway(json?.count);
      setLoadingRaleway(false);
    } catch (e) {
      console.log(e, 'err getRalewayOptions');
      message.error('Ошибка запроса на сервер', e);
      setLoadingRaleway(false);
    }
  };

  const getWaypoints = async () => {
    try {
      const url = `${restApiHost}/refbook/`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      if (Object.entries(json).length > 0) {
        setRefBookOptions([
          ...Object.entries(json),
          ['port', `/refbook/route_waypoint/?type_point=port`],
        ]);
        handleChangeOptions(Object.entries(json)[0][1]);
      }
    } catch (e) {
      console.log(e);
      message.error('Ошибка запроса на сервер', e);
    }
  };

  const getOptions = async (url) => {
    console.log(url);
    try {
      const res = await fetch(url, {
        method: 'OPTIONS',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      });
      const json = await res.json();

      if (res.status === 403) {
        message.error(json?.text ? json.text : 'У вас недостаточно прав');
        return;
      }
      if (res.status === 400 || res.status === 500) {
        message.error(json?.text ? json.text : 'Ошибка получения данных');
        return;
      }

      if (json?.actions?.POST) {
        setOptions(Object.entries(json.actions.POST));
      }
    } catch (e) {
      console.log(e, 'err getOptions');
      message.error('Ошибка запроса на сервер', e);
    }
  };
  const getContractPlaceholders = async () => {
    try {
      const url = `${restApiHost}/customers/placeholders/`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;
      setContactPlaceholders(json?.data);
    } catch (e) {
      message.error('Ошибка запроса получения плейсхолдеров', e);
    }
  };

  const getUrlForGetData = (
    originalUrl,
    searchKeyword,
    filterActive,
    page = 1,
  ) => {
    let copyUrl = originalUrl;
    let isParams = originalUrl.includes('?');
    if (searchKeyword) {
      copyUrl = `${copyUrl}${isParams ? '&' : '?'}search=${searchKeyword}`;
      isParams = true;
    }
    if (filterActive !== null && filterActive !== 'all') {
      copyUrl = `${copyUrl}${isParams ? '&' : '?'}active=${filterActive}`;
      isParams = true;
    }
    copyUrl += `${isParams ? '&' : '?'}page=${page}`;

    return copyUrl;
  };

  const getData = async (url, page) => {
    setLoading(true);
    console.log(url);
    const urlForQuery = getUrlForGetData(
      url,
      searchKeyword.current,
      selectFilterActivityValue,
      page,
    );

    try {
      const json = await call(
        urlForQuery,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      setData(json?.data);
      setTotal(json?.count);
      setLoading(false);
    } catch (e) {
      message.error('Ошибка запроса на сервер', e);
      console.log(e, 'err getData');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const getTabTitleRu = (tab) => {
    switch (tab) {
      case 'transport_units':
        return 'Транспорт';
      case 'cargo':
        return 'Грузы';
      case 'operation_waypoint':
        return 'Операции';
      case 'service':
        return 'Услуги';
      case 'railway':
        return 'ЖД-направления';
      case 'route_waypoint':
        return 'ЖД-станции';
      case 'port':
        return 'Порты';
      case 'template':
        return 'Шаблоны письма';
      case 'task_template':
        return 'Шаблоны заявок';
      case 'contract_template':
        return 'Шаблоны договоров';
      case 'call_result':
        return 'Итог звонка';
      case 'type_cargo_packing':
        return 'Типы упаковки';
      case 'strategic_arch_reason':
        return 'Причина попадания в стратегический архив';
      case 'cities':
        return 'Города';
      case 'presentations':
        return 'Презентации';
      case 'comm_prop_template':
        return 'Шаблоны КП';
      case 'calendar_tags':
        return 'Тэги задачи календаря';
      case 'timezone':
        return 'Временный зоны';
      case 'currency_rate':
        return 'Курсы валют';
      case 'currency':
        return 'Валюты';
      default:
        return tab;
    }
  };

  const handleChangeOptions = async (url, page = 1) => {
    currentPage.current = page;
    const absoluteUrl = `${restApiHost}${url}`;
    setSelectedOption(url);
    await getOptions(absoluteUrl);
    await getData(absoluteUrl, page);
  };

  const addToRefBook = async (url, body, useFormData) => {
    let headers = {
      Authorization: `Token ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    };
    if (useFormData) {
      headers = {
        Authorization: `Token ${localStorage.getItem('token')}`,
      };
    }
    try {
      setLoadingModal(true);
      const json = await call(
        url,
        {
          method: 'POST',
          headers,
          body: useFormData ? body : JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      message.success('Успешно добавлено!');
      await handleCancelModal();
    } catch (e) {
      console.log(e);
      await message.error('Ошибка запроса на добавление', 1);
    } finally {
      setLoadingModal(false);
    }
  };

  const editRefBook = async (url, body, useFormData) => {
    let headers = {
      Authorization: `Token ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    };
    if (useFormData) {
      headers = {
        Authorization: `Token ${localStorage.getItem('token')}`,
      };
    }
    try {
      setLoadingModal(true);
      const json = await call(
        url,
        {
          method: 'PATCH',
          headers,
          body: useFormData ? body : JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      message.success('Изменения прошли успешно!');
      await handleCancelModal();
    } catch (e) {
      console.log(e);
      await message.error('Ошибка запроса на редактирование', 1);
    } finally {
      setLoadingModal(false);
    }
  };

  const deleteRefBook = async (url, body) => {
    try {
      setLoadingModal(true);
      const response = await call(
        url,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!response) return;

      message.success('Удаление прошло успешно!');
      await handleCancelModal();
    } catch (e) {
      console.log(e);
      message.error('Ошибка запроса на удаление', 1);
    } finally {
      setLoadingModal(false);
    }
  };

  const objectToFormData = (object) => {
    const formData = new FormData();
    for (let key in object) {
      if (object[key] !== undefined) {
        object[key] === null
          ? formData.append(key, '')
          : formData.append(key, object[key]);
      }
    }
    return formData;
  };

  const submitForm = async (values, item, isDelete, makeFormData) => {
    let url = '';
    let body = { ...values };
    if (selectedOption.includes('?')) {
      url = selectedOption.split('?')[0];
    } else {
      url = selectedOption;
    }
    if (selectedOption.includes('?type_point=port')) {
      body = { ...body, type_point: 'port' };
    } else if (selectedOption.includes('?type_point=station')) {
      body = { ...body, type_point: 'station' };
    }
    if (isDelete) {
      if (item) {
        await deleteRefBook(`${restApiHost}${url}${item.id}/`, body);
        setDeleteItem(false);
      }
    } else {
      if (item) {
        await editRefBook(
          `${restApiHost}${url}${item.id}/`,
          makeFormData ? objectToFormData(body) : body,
          makeFormData,
        );
      } else {
        await addToRefBook(
          `${restApiHost}${url}`,
          makeFormData ? objectToFormData(body) : body,
          makeFormData,
        );
      }
    }
  };

  const onSearch = async (keyword) => {
    const url = `${restApiHost}${selectedOption}`;
    if (keyword.length > 0) {
      await getData(url, 1);
    } else {
      await handleChangeOptions(selectedOption, 1);
    }
  };

  const handleChangeFilter = async (value) => {
    if (value) {
      setSelectFilterActivityValue(value);
    }
    /*let url
        if (value === 'true') {
            url = `${selectedOption}&active=true`;
            await getData(url);
        } else if (value === 'false') {
            url = `${selectedOption}&active=false`;
            await getData(url);
        } else {
            await handleChangeOptions(selectedOption, 1);
        }*/
  };

  const searchPortOrWaypoint = async (keyword) => {
    let url = `${restApiHost}${selectedOption}`;
    if (keyword.length > 0) {
      searchKeyword.current = keyword;
    } else {
      searchKeyword.current = null;
    }
    await getData(url, 1);
  };

  const renderFilters = () => {
    return (
      <>
        <aside className={'Toolbars-wrapper-row'}>
          <div className={'Toolbars-row-item'}>
            <Search
              placeholder="Поиск по названию или коду"
              allowClear
              enterButton
              value={searchValueForInput}
              onChange={(e) => {
                setSearchValueForInput(e.target.value);
              }}
              onSearch={async (value) => {
                currentPage.current = 1;
                await searchPortOrWaypoint(value);
              }}
            />
          </div>
          <div className={'Toolbars-row-item'}>
            <Select
              getPopupContainer={(trigger) =>
                isMobile ? trigger.parentNode : document.body
              }
              defaultValue={'all'}
              className={'Refbook-select'}
              placeholder={'Фильтр по активности'}
              value={selectFilterActivityValue}
              onChange={handleChangeFilter}
            >
              <Option value={'true'}>Активные</Option>
              <Option value={'false'}>Не активные</Option>
              <Option value={'all'}>Все</Option>
            </Select>
            <Button style={{ color: '#0B7A7F' }} onClick={handleAddModal}>
              {isMobile ? '+' : 'Добавить в справочник'}
            </Button>
          </div>
        </aside>
      </>
    );
  };

  const handleAddModal = () => {
    editItem && setEditItem(null);
    form.setFieldsValue({
      branch: getUserBranch(userInfo.current),
    });
    setVisible(true);
  };

  const handleCancelModal = async () => {
    form.resetFields();
    setVisible(false);
    setEditItem(null);
    await handleChangeOptions(selectedOption, currentPage.current);
  };

  const getComponent = (type, item) => {
    const rules = item[1]?.required
      ? { rules: [{ required: true, message: 'Обязательно поле!' }] }
      : {};
    switch (type) {
      case 'boolean':
        return (
          <Col lg={12} xs={24} key={item[0]}>
            <Form.Item
              {...rules}
              valuePropName="checked"
              label={item[1]?.label}
              name={item[0]}
            >
              <Checkbox defaultChecked={false}>{item[1]?.label}</Checkbox>
            </Form.Item>
          </Col>
        );
      case 'choice':
        return (
          <Col lg={12} xs={24} key={item[0]}>
            <Form.Item {...rules} label={item[1]?.label} name={item[0]}>
              {item[0] === 'branch' ? (
                <Select
                  getPopupContainer={(trigger) =>
                    isMobile ? trigger.parentNode : document.body
                  }
                  disabled={true}
                  placeholder={item[1]?.label}
                >
                  {item[1]?.choices.map((choice) => {
                    return (
                      <Option value={choice.value} key={choice.value}>
                        {choice.display_name}
                      </Option>
                    );
                  })}
                </Select>
              ) : (
                <Select
                  getPopupContainer={(trigger) =>
                    isMobile ? trigger.parentNode : document.body
                  }
                  placeholder={item[1]?.label}
                >
                  {item[1]?.choices.map((choice) => {
                    return (
                      <Option value={choice.value} key={choice.value}>
                        {choice.display_name}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
          </Col>
        );
      case 'field':
        return (
          <Col lg={12} xs={24} key={item[0]}>
            <Form.Item {...rules} label={item[1]?.label} name={item[0]}>
              {isWaypointPage ? (
                <Select
                  getPopupContainer={(trigger) =>
                    isMobile ? trigger.parentNode : document.body
                  }
                  placeholder={item[1]?.label}
                  loading={loadingRaleWay}
                  optionFilterProp="children"
                  dropdownRender={(menu) => (
                    <div>
                      <Spin spinning={loadingRaleWay}>{menu}</Spin>
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ padding: '8px', textAlign: 'center' }}>
                        <Pagination
                          simple
                          current={pageForPaginationInSelect}
                          total={totalRaleWay}
                          pageSize={15}
                          onChange={async (pageIndex) => {
                            setPageForPaginationInSelect(pageIndex);
                            await getRalewayOptions(pageIndex);
                          }}
                        />
                      </div>
                    </div>
                  )}
                >
                  {ralewayOptions &&
                    ralewayOptions.map((option) => {
                      return (
                        <Option value={option.id} key={option.id}>
                          {option.name}
                        </Option>
                      );
                    })}
                </Select>
              ) : (
                <Select
                  getPopupContainer={(trigger) =>
                    isMobile ? trigger.parentNode : document.body
                  }
                  showSearch
                  placeholder={item[1]?.label}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {item[1]?.dataset && item[1]?.dataset.length > 0 ? (
                    <>
                      {item[1]?.dataset.map((item, index) => {
                        return (
                          <Option
                            key={index}
                            data-value={JSON.stringify(item)}
                            value={item.id}
                          >
                            {item.name}
                          </Option>
                        );
                      })}
                    </>
                  ) : (
                    <Option value={null} disabled>
                      Нет доступных вариантов
                    </Option>
                  )}
                </Select>
              )}
            </Form.Item>
          </Col>
        );
      case 'textarea':
        return (
          <Col lg={12} xs={24} key={item[0]}>
            <Form.Item {...rules} label={item[1]?.label} name={item[0]}>
              <TextArea placeholder={item[1]?.label} />
            </Form.Item>
          </Col>
        );
      default:
        return (
          <Col lg={12} xs={24} key={item[0]}>
            <Form.Item {...rules} label={item[1]?.label} name={item[0]}>
              <Input placeholder={item[1]?.label} />
            </Form.Item>
          </Col>
        );
    }
  };
  const filterColumns = (columns, form) => {
    let filteredColumns = [...columns];
    filteredColumns = filteredColumns.filter(
      (item) => item[0] !== 'type_point' && item[0] !== 'location',
    );

    if (selectedOption.includes('type_point=port')) {
      filteredColumns = filteredColumns.filter((item) => item[0] !== 'route');
    }
    if (selectedOption.includes('contract_template')) {
      filteredColumns = filteredColumns.filter(
        (item) => item[0] !== 'contract_type_str',
      );
    }
    if (form) {
      filteredColumns = filteredColumns.filter((item) => item[0] !== 'id');
    }

    return filteredColumns;
  };
  const setItemForEditing = (body) => {
    setEditItem(body);
    form.setFieldsValue({ ...body });
    setVisible(true);
  };

  const getActiveTab = (route) => {
    switch (true) {
      case route.includes('contract_template'):
        return 'contract_template';
      case route.includes('presentations'):
        return 'presentations';
      case route.includes('comm_prop_template'):
        return 'comm_prop_template';
      case route.includes('cities'):
        return 'cities';
      case route.includes('task_template'):
        return 'task_template';
      case route.includes('currency_rate'):
        return 'currency_rate';
      case route.includes('currency'):
        return 'currency';
      default:
        return null;
    }
  };

  const renderModalComponentByActiveTab = (activeTab) => {
    switch (activeTab) {
      case 'contract_template':
        return (
          <ErrorBoundary>
            <RefBookContractModal
              visible={visibleModal}
              editItem={editItem}
              deleteItem={deleteItem}
              form={form}
              contactPlaceholders={contactPlaceholders}
              setDeleteItem={(del) => setDeleteItem(del)}
              handleCancelModal={() => handleCancelModal}
              submitForm={(values, editItem, deleteItem, useFormDate) =>
                submitForm(values, editItem, deleteItem, useFormDate)
              }
              fields={filterColumns(options, true)}
              getComponent={(type, item) => getComponent(type, item)}
            />
          </ErrorBoundary>
        );
      case 'presentations':
        return (
          <ErrorBoundary>
            <RefBookPresentationsModal
              visible={visibleModal}
              editItem={editItem}
              deleteItem={deleteItem}
              form={form}
              setDeleteItem={(del) => setDeleteItem(del)}
              handleCancelModal={() => handleCancelModal}
              submitForm={async (values, editItem, deleteItem, makeFormData) =>
                await submitForm(values, editItem, deleteItem, makeFormData)
              }
              loading={loadingModal}
              fields={filterColumns(options, true)}
              getComponent={(type, item) => getComponent(type, item)}
            />
          </ErrorBoundary>
        );
      case 'comm_prop_template':
        return (
          <ErrorBoundary>
            <RefBookCPModal
              visible={visibleModal}
              loading={loading}
              form={form}
              fields={filterColumns(options, true)}
              editingItem={editItem}
              deleteItem={deleteItem}
              setDeleteItem={(del) => setDeleteItem(del)}
              getComponent={(type, item) => getComponent(type, item)}
              submitForm={(values, editItem, deleteItem) =>
                submitForm(values, editItem, deleteItem)
              }
              handleCancelModal={() => handleCancelModal}
            />
          </ErrorBoundary>
        );
      case 'cities':
        return (
          <>
            <ErrorBoundary>
              <CitesModal
                visible={visibleModal}
                loading={loading}
                form={form}
                fields={filterColumns(options, true)}
                editingItem={editItem}
                deleteItem={deleteItem}
                setDeleteItem={(del) => setDeleteItem(del)}
                getComponent={(type, item) => {
                  return getComponent(type, item);
                }}
                submitForm={async (values, editItem, deleteItem) => {
                  await submitForm(values, editItem, deleteItem);
                }}
                handleCancelModal={() => {
                  handleCancelModal();
                }}
              />
            </ErrorBoundary>
          </>
        );
      case 'task_template':
        return (
          <ErrorBoundary>
            <RefbookTaskTemplateModal
              visibleModal={visibleModal}
              editItem={editItem}
              formField={filterColumns(options, true)}
              form={form}
              getComponent={(type, item) => {
                return getComponent(type, item);
              }}
              submitForm={async (values, editItem, deleteItem) =>
                await submitForm(values, editItem, deleteItem)
              }
              closeModal={() => handleCancelModal()}
            />
          </ErrorBoundary>
        );
      default:
        return renderModal();
    }
  };

  const prepareDate = useMemo(() => {
    let copyData = [...data];
    if (activeTabBook === 'currency_rate') {
      copyData = copyData.map((item) => {
        return {
          divider_currency: item?.divider_currency?.name,
          id: item.id,
          multiple_currency: item?.multiple_currency?.name,
          multiplier: item?.multiplier,
        };
      });
    }
    return copyData;
  }, [data, activeTabBook]);

  const renderModal = () => {
    return (
      <Modal
        title={`${editItem ? 'Редактирование' : 'Добавление'}`}
        visible={visibleModal}
        onCancel={handleCancelModal}
        footer={[]}
      >
        <Form
          layout={'vertical'}
          form={form}
          onFinish={(values) => {
            editItem
              ? deleteItem
                ? submitForm(values, editItem, deleteItem)
                : submitForm(values, editItem)
              : submitForm(values);
          }}
        >
          <Row gutter={16}>
            {filterColumns(options, true).map((item) => {
              return getComponent(item[1]?.type, item, editItem);
            })}
          </Row>
          <Row justify={'end'}>
            <Col>
              <Button type={'primary'} htmlType={'submit'}>
                {editItem ? 'Сохранить' : 'Добавить'}
              </Button>
            </Col>
            <Col style={{ marginLeft: '10px' }}>
              {editItem ? (
                <Button onClick={() => setDeleteItem(true)} htmlType={'submit'}>
                  {'Удалить'}
                </Button>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {formationDocumentTitle('Справочник | Квик Транс', notificationCount)}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        {renderModalComponentByActiveTab(activeTabBook)}
        <Container maxWidth={false}>
          <Box sx={{ pt: 3 }}>
            <Row justify={'end'} style={{ flexFlow: 'row', marginBottom: 10 }}>
              {renderFilters()}
            </Row>
            <Tabs
              tabPosition={'top'}
              type="card"
              onTabClick={(key) => {
                const activeTab = getActiveTab(key);
                setActiveTabBook(activeTab);
                if (
                  !key.includes('?type_point=port') &&
                  key.includes('route_waypoint')
                ) {
                  setSearchValueForInput('');
                  handleChangeOptions(
                    `/refbook/route_waypoint/?type_point=station`,
                    1,
                  );
                } else {
                  setSearchValueForInput('');
                  handleChangeOptions(key, 1);
                }
              }}
            >
              {refBookOptions.map((ref) => {
                return (
                  <TabPane tab={`${getTabTitleRu(ref[0])}`} key={`${ref[1]}`}>
                    <ErrorBoundary>
                      <RefBookTable
                        loading={loading}
                        data={prepareDate}
                        columnsData={options}
                        total={total}
                        page={currentPage.current}
                        handleChangePage={(page) =>
                          handleChangeOptions(selectedOption, page)
                        }
                        selectedOption={selectedOption}
                        setItemForEditing={(body) => setItemForEditing(body)}
                        submitForm={(
                          values,
                          editItem,
                          deleteItem,
                          useFormDate,
                        ) =>
                          submitForm(values, editItem, deleteItem, useFormDate)
                        }
                        ralewayOptions={ralewayOptions}
                        totalRaleway={totalRaleWay}
                        loadingRaleway={loadingRaleWay}
                        onSearch={onSearch}
                        handleChangeFilter={handleChangeFilter}
                      />
                    </ErrorBoundary>
                  </TabPane>
                );
              })}
            </Tabs>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Refbook;
