import PropTypes from 'prop-types';
import { Col, Form, Row, Select } from 'antd';
import React, { useCallback, useContext, useState } from 'react';
import useCheckMobileScreen from '../../../../../../../hooks/useCheckMobileScreen';
import useAsyncEffect from 'use-async-effect';
import { getListOperationWaypoint } from '../../../../../../../logic/refbook/operationWaypoint/OperationWaypointApiFunctions';
import { userInformations } from '../../../../../../../contex';

const { Option } = Select;

const TemplateOperationModalForm = (props) => {
  const { userInfo } = useContext(userInformations);
  const isMobile = useCheckMobileScreen();
  const [operationsOptionsList, setOperationsOptionsList] = useState([]);

  useAsyncEffect(async () => {
    await handleGetOperations();
  }, []);

  const handleGetOperations = useCallback(
    async (keyword = null) => {
      const res = await getListOperationWaypoint(userInfo, keyword);
      if (res?.data) {
        setOperationsOptionsList(res.data);
      }
    },
    [setOperationsOptionsList],
  );

  return (
    <>
      <Form form={props.form} layout={'vertical'}>
        <Row gutter={16}>
          <Col xs={24} lg={24}>
            <Form.Item
              name={'operation'}
              label={'Операция'}
              rules={[{ required: true, message: 'Обязательное поле!' }]}
            >
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
                showSearch
                // defaultValue={record && record.operation ? record.operation.id : ''}
                disabled={props.editingWaypoint?.id}
                placeholder="Поиск операции"
              >
                {operationsOptionsList && operationsOptionsList.length > 0 ? (
                  <>
                    {operationsOptionsList.map((item) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </>
                ) : (
                  <Option disabled value="Загрузка">
                    Загрузка
                  </Option>
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

TemplateOperationModalForm.propTypes = {
  form: PropTypes.any.isRequired,
  editingOperation: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  visibleModal: PropTypes.bool,
};

TemplateOperationModalForm.defaultProps = {
  editingOperation: false,
  visibleModal: false,
};

export default TemplateOperationModalForm;
