import { useCallback, useMemo, useState, useContext } from 'react';
import { Table, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { userInformations } from '../../../../../../contex';
import './../operations/TemplateOperation.scss';
import { editTaskTemplateOperation } from '../../../../../../logic/refbook/tasksTemplate/TaskTempalteApiFunctions';
import ErrorBoundary from 'antd/es/alert/ErrorBoundary';
import TemplateOperationServicesModal from './tempalteOperationServicesModal/TemplateOperationServicesModal';
import { MenuOutlined } from '@ant-design/icons';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

const TemplateOperationServicesTable = (props) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [editingOperation, setEditingOperation] = useState(false);
  const { userInfo } = useContext(userInformations);
  const { dataList } = props;

  const SortableItem = SortableElement((props) => <tr {...props} />);
  const SortableBody = SortableContainer((props) => <tbody {...props} />);

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const onSortEnd = useCallback(
    async ({ oldIndex, newIndex }) => {
      if (oldIndex !== newIndex) {
        try {
          const newData = arrayMoveImmutable(
            [...dataList],
            oldIndex,
            newIndex,
          ).filter((el) => !!el);

          const updatedData = newData.map((operation, idx) => ({
            ...operation,
            index: idx + 1,
          }));

          props.setOperationDataList(updatedData);

          const responses = await Promise.all(
            updatedData.map((operation) =>
              editTaskTemplateOperation(
                operation.id,
                {
                  index: operation?.index,
                },
                userInfo,
              ),
            ),
          );

          responses.forEach((response, idx) => {
            const expectedIndex = updatedData[idx].index;
            const operationId = updatedData[idx].id;

            console.log(`Ответ для операции ${operationId}:`, response);

            const receivedIndex = response?.index;

            if (expectedIndex !== receivedIndex) {
              console.warn(
                `Несоответствие индексов для операции ${operationId}:`,
                `\nОжидался индекс: ${expectedIndex}`,
                `\nПолучен индекс: ${receivedIndex}`,
                `\nСтруктура ответа:`,
                response,
              );
            }
          });
        } catch (error) {
          console.error('Ошибка при обновлении порядка операций:', error);
          props.setOperationDataList(dataList);
        }
      }
    },
    [dataList, userInfo],
  );

  const DraggableBodyRow = useCallback(
    ({ ...restProps }) => {
      const index = dataList?.findIndex(
        (x) => x?.index === restProps['data-row-key'],
      );

      return <SortableItem index={index} {...restProps} />;
    },
    [dataList],
  );

  const DragHandle = SortableHandle(() => (
    <MenuOutlined
      style={{
        cursor: 'grab',
        color: '#999',
      }}
    />
  ));

  const handleEditOperation = useCallback(
    (editingOperation) => {
      if (!editingOperation) return;
      setEditingOperation(editingOperation);
      setVisibleModal(true);
    },
    [setEditingOperation],
  );

  const tableColumns = [
    {
      title: '',
      dataIndex: 'sort',
      width: 70,
      align: 'center',
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    // Для более удобного дебага
    // {
    //   title: 'Index',
    //   dataIndex: 'index',
    //   width: 70,
    //   align: 'center',
    // },
    // {
    //   title: 'ID',
    //   dataIndex: 'id',
    //   width: 70,
    //   align: 'center',
    // },
    {
      title: 'Операция',
      dataIndex: 'operation',
      key: 'operation',
      uniqueIndex: 'operation',
      index: 2,
      ellipsis: {
        showTitle: false,
      },
      render: (operation) => {
        console.log('operation', operation);
        return operation?.operation?.name ? (
          <Tooltip placement="topLeft" title={operation.operation.name}>
            {operation.operation.name}
          </Tooltip>
        ) : (
          ''
        );
      },
    },
    {
      title: 'Услуга',
      dataIndex: 'service',
      key: 'service',
      index: 3,
      ellipsis: {
        showTitle: false,
      },
      render: (service) => {
        console.log('service', service);
        return service?.name ? (
          <Tooltip placement="topLeft" title={service.name}>
            {service.name}
          </Tooltip>
        ) : (
          ''
        );
      },
    },
  ];

  const handleCloseModal = useCallback(() => {
    if (props.handleUpdateDataListAsync) {
      props.handleUpdateDataListAsync(props.templateId);
    }
    setVisibleModal(false);
  }, []);

  const dataListLength = useMemo(() => {
    return dataList?.length ?? 0;
  }, [dataList]);

  console.log('Рендер состояния услуг:', dataList);

  // В текущей реализации для всех операций кроме удаленной вызывается PATCH с обновлением их индексов
  // TODO: В теории это можно делать на бекенде при DELETE запросе автоматически
  // const handleOperationDeleted = useCallback(
  //   async (id) => {
  //     try {
  //       // Перерасчитываем индексы для операций кроме удаленной
  //       const updatedDataList = dataList
  //         .filter((operation) => operation.id !== id)
  //         .map((operation, idx) => ({
  //           ...operation,
  //           index: idx + 1,
  //         }));

  //       await Promise.all(
  //         updatedDataList.map((operation) =>
  //           editTaskTemplateOperation(
  //             operation.id,
  //             {
  //               ...operation,
  //             },
  //             userInfo,
  //           ),
  //         ),
  //       );

  //       props.setOperationDataList(updatedDataList);
  //       props.handleUpdateDataList();
  //     } catch (error) {
  //       console.error('Ошибка при обновлении индексов операций:', error);
  //       props.setOperationDataList(dataList);
  //     }
  //   },
  //   [
  //     dataList,
  //     userInfo,
  //     props.setOperationDataList,
  //     props.handleUpdateDataList,
  //   ],
  // );

  return (
    <>
      <Table
        // Редактирование пока не реализовано
        onRow={(operation) => {
          return {
            onDoubleClick: (e) => {
              handleEditOperation(operation);
              e.stopPropagation();
            },
          };
        }}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
        rowClassName={(record, index) =>
          index % 2 === 0
            ? 'black-text table-row-color'
            : 'black-text table-row-white'
        }
        rowKey="index"
        loading={props.loading}
        size="small"
        pagination={{
          position: ['none'],
          size: 'middle',
          showSizeChanger: false,
        }}
        columns={tableColumns}
        dataSource={dataList}
        bordered={false}
      />
      <ErrorBoundary>
        <TemplateOperationServicesModal
          visible={visibleModal}
          templateId={props.templateId}
          editingOperation={editingOperation}
          closeModal={handleCloseModal}
          operationsListLength={dataListLength}
          // onOperationDeleted={handleOperationDeleted}
        />
      </ErrorBoundary>
    </>
  );
};

TemplateOperationServicesTable.propTypes = {
  templateId: PropTypes.number,
  handleUpdateDataListAsync: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default TemplateOperationServicesTable;
