import React, { useCallback, useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Button, Space, Tooltip } from 'antd';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import { priceFormatter } from '../../../../../../../utils/priceFormatter';
import PropTypes from 'prop-types';
import { ErrorBoundary } from '../../../../../../ErrorBoundary';
import CustomerBillsTable from './CustomerBillsTable';
import CustomerBillsModal from './modal/CustomerBillsModal';
import useStateRef from 'react-usestateref';
import { FilterOutlined } from '@ant-design/icons';
import useCheckMobileScreen from '../../../../../../../hooks/useCheckMobileScreen';
import useUserInfo from '../../../../../../../hooks/useUserInfo';
import {
  getClientCostsList,
  getClientCostsTotalSums,
} from '../../../../../../../logic/task/TaskApiFunctions';

const CustomerBillsTab = (props) => {
  const { refreshTaskInfo, taskData } = props;
  const userInfo = useUserInfo();
  const isMobile = useCheckMobileScreen();

  const [visibleModal, setVisibleModal] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [, setVisibleFilters, visibleFiltersRef] = useStateRef(false);
  const [, setTableFilters, tableFiltersRef] = useStateRef(null);
  const [totals, setTotals] = useState({
    //total_sum: 0,
    //total_sum_with_vat: 0,
    total_sum_currency: {
      sum_with_vat: 0,
      sum: 0,
    },
  });

  useAsyncEffect(async () => {
    await handleGetCostsList();
  }, []);

  useAsyncEffect(async () => {
    if (!visibleFiltersRef.current && !isMobile) {
      await handleGetCostsList(null);
    }
  }, [visibleFiltersRef.current, isMobile]);

  const handleGetCostsList = useCallback(
    async (params = null) => {
      setTableFilters(params);
      try {
        setLoading(true);

        const res = await getClientCostsList(taskData?.id, params, userInfo);

        setDataList(res ?? []);
        await handleGetTotalSums();
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [setDataList, setLoading, setTableFilters],
  );

  const handleGetTotalSums = useCallback(async () => {
    try {
      const res = await getClientCostsTotalSums(taskData?.id, 129, userInfo);
      if (res) {
        setTotals(res);
      }
    } catch (e) {
      console.log(e);
    }
  }, [taskData, userInfo]);

  const handleAddCost = useCallback(() => {
    setVisibleModal(true);
  }, [setVisibleModal]);

  const handleCloseModal = useCallback(async () => {
    setVisibleModal(false);
    await handleGetCostsList(tableFiltersRef.current);
  }, [setVisibleModal, tableFiltersRef.current]);

  return (
    <>
      <div className={'top-table-actions'}>
        <Space>
          <Tooltip placement={'topLeft'} title={'Добавить'}>
            <Button
              type="primary"
              size={'small'}
              icon={<PlusOutlined />}
              onClick={() => handleAddCost()}
            />
          </Tooltip>
          <Tooltip placement={'topLeft'} title={'Отобразить фильтры'}>
            <Button
              size={'small'}
              type="primary"
              icon={<FilterOutlined />}
              onClick={() => {
                setVisibleFilters(!visibleFiltersRef.current);
              }}
            />
          </Tooltip>
        </Space>
      </div>
      <ErrorBoundary>
        <CustomerBillsTable
          dataList={dataList}
          taskData={taskData}
          loading={loading}
          visibleFilters={visibleFiltersRef.current}
          tableFilters={tableFiltersRef.current}
          setVisibleFilters={(val) => {
            setVisibleFilters(val);
          }}
          handleUpdateList={async (tableFilters) =>
            await handleGetCostsList(tableFilters)
          }
          refreshTaskInfo={() => {
            refreshTaskInfo();
          }}
        />
        <div
          style={{
            width: '100%',
            display: 'flex',
            textAlign: 'right',
            marginLeft: 'auto',
          }}
        >
          <div style={{ flexGrow: '1', padding: '12px' }}>
            <h4>Всего</h4>
            <b>
              {priceFormatter(totals.total_sum_currency.sum.toFixed(2) ?? 0)}
            </b>
          </div>

          <div style={{ padding: '12px' }}>
            <h4>Всего (У)</h4>
            <b>
              {priceFormatter(
                totals.total_sum_currency.sum_with_vat.toFixed(2) ?? 0,
              )}
            </b>
          </div>
        </div>
      </ErrorBoundary>
      <ErrorBoundary>
        <CustomerBillsModal
          visible={visibleModal}
          taskData={taskData}
          closeModal={async () => await handleCloseModal()}
          refreshTaskInfo={() => {
            refreshTaskInfo();
          }}
        />
      </ErrorBoundary>
    </>
  );
};

export default CustomerBillsTab;

CustomerBillsTab.propTypes = {
  taskData: PropTypes.object.isRequired,
  refreshTaskInfo: PropTypes.func.isRequired,
};
