import PropTypes from 'prop-types';
import { Button, Form, message, Modal } from 'antd';

import React, { useCallback, useContext, useEffect } from 'react';
import TemplateOperationModalForm from './TemplateOperationModalForm';
import { userInformations } from '../../../../../../../contex';
import {
  addTaskTemplateOperation,
  editTaskTemplateOperation,
  deleteTaskTemplateOperation,
} from '../../../../../../../logic/refbook/tasksTemplate/TaskTempalteApiFunctions';

const TemplateOperationModal = (props) => {
  const { userInfo } = useContext(userInformations);
  const [form] = Form.useForm();

  useEffect(() => {
    if (props.editingOperation) {
      form.setFieldsValue({
        operation: props.editingOperation.operation?.id,
      });
    }
  }, [props.editingOperation]);
  const handleCloseModal = useCallback(() => {
    props.closeModal && props.closeModal();
  }, [props.closeModal]);

  const handleCreateOrEditOperation = useCallback(() => {
    const isEdtiting = !!props.editingOperation?.id;
    form.validateFields().then(async (values) => {
      const body = {
        ...values,
        // TODO: тут можно либо получать название операции
        // либо на бекенд надо добавить поддержку подобных запросов на создание операций без указания waypoint_name
        waypoint_name: 'waypoint_name',
        index: isEdtiting
          ? props.editingOperation.id
          : props.operationsListLength + 1,
      };

      if (!isEdtiting) {
        body.template = props.templateId;
        console.log('Создание новой операции...', body);
      } else {
        console.log('Редактирование операции...', body);
      }
      const res = isEdtiting
        ? await editTaskTemplateOperation(
            props.editingOperation.id,
            body,
            userInfo,
          )
        : await addTaskTemplateOperation(body, userInfo);

      if (res) {
        message.success(
          props.editingWaypoint?.id
            ? 'Операция успешно отредактирована'
            : 'Операция успешно добавлен',
        );
        form.resetFields();
        handleCloseModal();
      }
    });
  }, [form, props.editingOperation, props.operationsListLength]);

  const handleDeleteOperation = useCallback(() => {
    Modal.confirm({
      title: 'Подтверждение удаления',
      content: 'Вы действительно хотите удалить эту операцию?',
      okText: 'Да, удалить',
      cancelText: 'Отмена',
      okButtonProps: { danger: true },
      onOk: async () => {
        const success = await deleteTaskTemplateOperation(
          props.editingOperation.id,
          userInfo,
        );

        if (success) {
          message.success('Операция успешно удалена');
          handleCloseModal();

          props.onOperationDeleted &&
            props.onOperationDeleted(props.editingOperation.id);
        }
      },
    });
  }, [props.editingOperation, userInfo]);

  return (
    <>
      <Modal
        visible={props.visible}
        destroyOnClose={true}
        zIndex={1000}
        title={
          props.editingOperation
            ? 'Редактировать операцию'
            : 'Добавить операцию'
        }
        onOk={() => {
          handleCloseModal();
        }}
        onCancel={() => {
          handleCloseModal();
        }}
        footer={[
          props.editingOperation && (
            <Button key="delete" danger onClick={handleDeleteOperation}>
              Удалить
            </Button>
          ),
          <Button key="back" onClick={handleCloseModal}>
            Закрыть
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleCreateOrEditOperation}
          >
            Сохранить
          </Button>,
        ].filter(Boolean)}
      >
        <TemplateOperationModalForm
          form={form}
          editingOperation={props.editingOperation}
        />
      </Modal>
    </>
  );
};

export default TemplateOperationModal;

TemplateOperationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  editingOperation: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  templateId: PropTypes.number,
  operationsListLength: PropTypes.number.isRequired,
  closeModal: PropTypes.func,
  onOperationDeleted: PropTypes.func,
};
