import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  Menu,
  message,
  Popconfirm,
  Space,
  Table,
  Tooltip,
  Checkbox,
} from 'antd';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';
import { priceFormatter } from '../../../../../../../utils/priceFormatter';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ErrorBoundary } from '../../../../../../ErrorBoundary';
import useCheckMobileScreen from '../../../../../../../hooks/useCheckMobileScreen';
import useUserInfo from '../../../../../../../hooks/useUserInfo';
import ScrollingLoadedList from '../../../../../../mobile/scrollingLoadedList/ScrollingLoadedList';
import CostListItem from '../../../../../../mobile/task/modal/CostListItem';
import useStateRef from 'react-usestateref';
import {
  changeColumnSizeInColumnsGroup,
  changeSizeColumn,
  convertingColumnsWithGroupsIntoRegularOnesForUseInMobileFilters,
  prepareColumnsForResizing,
  sortingColumnsTable,
} from '../../../../../../settingTable/sortingColumnsTable';
import { editTableColumn } from '../../../../../../../logic/profileInfo/mainTable/MainTableApiFunctions';
import { getProfileInfo } from '../../../../../../../logic/account/AccountsApiFunctions';
import { userInfoHaveViewAllBranch } from '../../../../../../../logic/account/utils';
import filterActiveColumns from '../../../../../../table/utils';
import SearchDataFilter from '../../../../../../leeds/filters/SearchData';
import ResizableTableColumnHeader from '../../../../../../table/ResizableTableColumnHeader';
import { getFiltersTable } from '../../../../../../../utils/getFilters';
import RangeNumberFilters from '../../../../../../leeds/filters/RangeNumberFilters';
import FilterSelect from '../../../../../../leeds/filters/FilterSelect';
import UniversalFiltersModal from '../../../../../../mobile/filtersModal/UniversalFiltersModal';
import CustomerBillsModal from './modal/CustomerBillsModal';
import useCurrencySearch from '../../../../../../../hooks/useCurrencySearch';
import { deleteClientCost } from '../../../../../../../logic/task/TaskApiFunctions';

const CustomerBillsTable = (props) => {
  const {
    dataList,
    visibleFilters,
    tableFilters,
    handleUpdateList,
    taskData,
    loading,
    refreshTaskInfo,
    setVisibleFilters,
  } = props;

  const userInfo = useUserInfo();
  console.log('userInfo', userInfo);
  const {
    searchCurrencies,
    currencies,
    //loadingCurrencies,
    //currenciesError
  } = useCurrencySearch();
  const [editingCost, setEditingCost] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const isMobile = useCheckMobileScreen();
  const [baseColumns, setBaseColumns, baseColumnsRef] = useStateRef([]);
  const [
    ,
    setColumnFiltersFromTableComponent,
    columnFiltersFromTableComponentRef,
  ] = useStateRef([]);
  const [, setCachedCurrencies, cachedCurrenciesRef] = useStateRef({});

  useEffect(() => {
    const tableName = 'customer_bills';

    const col = sortingColumnsTable(
      userInfo,
      tableName,
      columns,
      visibleFilters,
    );
    const preparedColumnsForResizing = prepareColumnsForResizing(
      col,
      handleResize,
    );
    console.log(col, preparedColumnsForResizing);
    setBaseColumns(preparedColumnsForResizing);
  }, [visibleFilters]);

  // console.log('customers', customers);
  // console.log('taskData', taskData);
  // console.log('tableFilters', tableFilters);
  // console.log('visibleFilters', visibleFilters);
  // console.log('data', data);

  useEffect(() => {
    if (!tableFilters) {
      setColumnFiltersFromTableComponent([]);
    }
  }, [tableFilters]);

  const handleEditConst = useCallback(
    (constRow) => {
      if (!constRow) return;
      setEditingCost(constRow);
      setVisibleModal(true);
    },
    [setEditingCost, setVisibleModal],
  );

  const handleCloseModal = useCallback(async () => {
    setVisibleModal(false);
    handleUpdateList(tableFilters);
  }, [setVisibleModal, handleUpdateList, tableFilters]);

  const handleDelete = useCallback(
    async (itemId) => {
      // TODO: Добавить запрос на бекенд о удалении customer_bills
      // Удалить после реализации бекенда
      const res = await deleteClientCost(itemId, userInfo);
      if (res) {
        message.success('Сумма клиенту успешно удалена');
        handleUpdateList(tableFilters);
      }
    },
    [handleUpdateList, tableFilters],
  );

  const menu = (cost) => {
    return (
      <Menu style={{ width: 200 }}>
        <Menu.Item
          key="menu-edit"
          icon={<EditOutlined />}
          onClick={() => {
            handleEditConst(cost);
          }}
        >
          Редактировать
        </Menu.Item>
        <Popconfirm
          title="Вы действительно хотите удалить запись?"
          onConfirm={async () => {
            await handleDelete(cost?.id);
          }}
          okText="Да"
          cancelText="Нет"
          placement="rightBottom"
        >
          <Menu.Item key="menu-delete" icon={<DeleteOutlined />}>
            Удалить
          </Menu.Item>
        </Popconfirm>
      </Menu>
    );
  };

  const handleRenderCurrency = useCallback((currency) => {
    switch (currency) {
      case 'rub':
        return 'Рубли';
      case 'dol':
        return 'Доллар';
      case 'eur':
        return 'Евро';
      case 'cny':
        return 'Юань';
      default:
        return '-';
    }
  }, []);

  const handleTableChange = (filtersTable) => {
    const { filters } = getFiltersTable({ current: 1 }, filtersTable);
    setColumnFiltersFromTableComponent(filtersTable);
    handleUpdateList(filters);
  };

  // Обновляем кэш при получении новых данных о валютах
  useEffect(() => {
    if (currencies.length > 0) {
      setCachedCurrencies((prev) => ({
        ...prev,
        [currencies[0].id]: currencies[0],
      }));
    }
  }, [currencies]);

  const renderCurrency = useCallback((currencyId) => {
    const currencyInfo = cachedCurrenciesRef.current[currencyId];
    const result = currencyInfo ? currencyInfo.name : '-';
    return result;
  }, []);

  // Загружаем информацию о валютах при изменении данных таблицы
  useEffect(() => {
    const loadCurrencyInfo = async (currencyId) => {
      try {
        if (!cachedCurrenciesRef.current[currencyId]) {
          await searchCurrencies({ id: currencyId });
        }
      } catch (error) {
        console.error('Error loading currency:', error);
      }
    };

    const loadAllCurrencies = async () => {
      const uniqueCurrencyIds = [
        ...new Set(dataList.map((item) => item.currency).filter(Boolean)),
      ];

      for (const currencyId of uniqueCurrencyIds) {
        await loadCurrencyInfo(currencyId);
      }
    };

    loadAllCurrencies();
  }, [dataList, searchCurrencies]);

  const columns = [
    {
      title: 'Исп. в счете',
      dataIndex: 'use_in_bill',
      key: 'use_in_bill',
      width: 90,
      align: 'center',
      index: 0,
      uniqueIndex: 'use_in_bill',
      visible: true,
      render: (_, record) => (
        <Checkbox
          defaultChecked={true}
          onChange={(e) => {
            // Здесь можно добавить обработчик изменения состояния
            console.log('Use in bill changed:', record.id, e.target.checked);
          }}
        />
      ),
    },
    {
      title: '',
      key: 'action',
      width: 50,
      align: 'center',
      index: 1,
      uniqueIndex: 'action',
      visible: true,
      render: (costs) => (
        <Space size="middle">
          <Dropdown overlay={menu(costs)} trigger={['hover']}>
            <EllipsisOutlined style={{ cursor: 'pointer', fontSize: '24px' }} />
          </Dropdown>
        </Space>
      ),
    },

    {
      title: 'Операция',
      dataIndex: 'operation',
      key: 'operation',
      width: 200,
      index: 4,
      uniqueIndex: 'operation',
      ellipsis: {
        showTitle: false,
      },
      render: (operation) =>
        operation?.name ? (
          <Tooltip title={operation.name} placement={'topLeft'}>
            {operation.name}
          </Tooltip>
        ) : (
          ''
        ),
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Операция'}
              confirm={(filtersTable) => handleTableChange(filtersTable)}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current
                  ?.operation__name__icontains
              }
              columnKey={'operation__name__icontains'}
            />
          ),
          dataIndex: 'operation',
          key: 'operation',
          width: 200,
          index: 4,
          uniqueIndex: 'operation',
          ellipsis: {
            showTitle: false,
          },
          visible: visibleFilters,
          render: (operation) =>
            operation?.name ? (
              <Tooltip title={operation.name} placement={'topLeft'}>
                {operation.name}
              </Tooltip>
            ) : (
              ''
            ),
        },
      ],
    },
    {
      title: 'Наименование',
      dataIndex: 'service',
      key: 'service',
      index: 6,
      uniqueIndex: 'service',
      width: 190,
      ellipsis: {
        showTitle: false,
      },
      render: (service) =>
        service?.name ? (
          <Tooltip title={service.name} placement={'topLeft'}>
            {service.name}
          </Tooltip>
        ) : (
          ''
        ),
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Наименование'}
              confirm={(filtersTable) => handleTableChange(filtersTable)}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current
                  ?.service__name__icontains
              }
              columnKey={'service__name__icontains'}
            />
          ),
          dataIndex: 'service',
          index: 6,
          uniqueIndex: 'service',
          width: 190,
          ellipsis: {
            showTitle: false,
          },
          visible: visibleFilters,
          render: (service) =>
            service?.name ? (
              <Tooltip title={service.name} placement={'topLeft'}>
                {service.name}
              </Tooltip>
            ) : (
              ''
            ),
        },
      ],
    },
    {
      title: 'Валюта',
      uniqueIndex: 'currencyGroup',
      isGroup: true,
      columnId: 'active',
      disableResize: true,
      index: 1,
      children: [
        {
          title: 'Вал.',
          key: 'currency',
          dataIndex: 'currency',
          uniqueIndex: 'currency',
          index: 8,
          width: 100,
          render: renderCurrency,
          children: [
            {
              title: (
                <FilterSelect
                  confirm={(filtersTable) => handleTableChange(filtersTable)}
                  tableFilters={columnFiltersFromTableComponentRef.current}
                  selectedKeys={
                    columnFiltersFromTableComponentRef.current?.currency
                  }
                  columnKey={'currency'}
                  dataList={[
                    { title: 'Рубли', value: 129 },
                    { title: 'Доллар', value: 159 },
                    { title: 'Евро', value: 51 },
                    { title: 'Юань', value: 35 },
                  ]}
                />
              ),
              key: 'currency',
              dataIndex: 'currency',
              uniqueIndex: 'currency',
              index: 8,
              width: 100,
              visible: visibleFilters,
              render: renderCurrency,
            },
          ],
        },
        {
          title: 'Курс',
          key: 'currency_rate',
          dataIndex: 'currency_rate',
          width: 70,
          uniqueIndex: 'currency_rate',
          index: 10,
          render: (rate) => {
            return rate || '-';
          },
          children: [
            {
              title: (
                <RangeNumberFilters
                  confirm={(filtersTable) => handleTableChange(filtersTable)}
                  tableFilters={columnFiltersFromTableComponentRef.current}
                  selectedKeys={
                    columnFiltersFromTableComponentRef.current
                      ?.currency_rates__range
                  }
                  columnKey={'currency_rates__range'}
                />
              ),
              key: 'currency_rate',
              dataIndex: 'currency_rate',
              width: 70,
              uniqueIndex: 'currency_rate',
              index: 10,
              visible: visibleFilters,
            },
          ],
        },
      ],
    },
    {
      title: 'Цена',
      uniqueIndex: 'priceGroup',
      isGroup: true,
      columnId: 'active',
      disableResize: true,
      index: 2,
      children: [
        {
          title: 'Вал.',
          key: 'price_currency',
          width: 150,
          uniqueIndex: 'price_currency',
          index: 12,
          render: (data) =>
            data?.in_currency
              ? priceFormatter(+data.price_currency)
              : priceFormatter(data.price),
          children: [
            {
              title: (
                <RangeNumberFilters
                  confirm={(filtersTable) => handleTableChange(filtersTable)}
                  tableFilters={columnFiltersFromTableComponentRef.current}
                  selectedKeys={
                    columnFiltersFromTableComponentRef.current
                      ?.price_currency__range
                  }
                  columnKey={'price_currency__range'}
                />
              ),
              key: 'price_currency',
              width: 150,
              uniqueIndex: 'price_currency',
              index: 12,
              visible: visibleFilters,
              render: (data) =>
                data?.in_currency
                  ? priceFormatter(+data.price_currency)
                  : priceFormatter(data.price),
            },
          ],
        },
        {
          title: 'Вал.(У)',
          key: 'price',
          dataIndex: 'price',
          width: 150,
          uniqueIndex: 'price',
          index: 14,
          render: (price) => (price ? priceFormatter(price) : 0),
          children: [
            {
              title: (
                <RangeNumberFilters
                  confirm={(filtersTable) => handleTableChange(filtersTable)}
                  tableFilters={columnFiltersFromTableComponentRef.current}
                  selectedKeys={
                    columnFiltersFromTableComponentRef.current?.price__range
                  }
                  columnKey={'price__range'}
                />
              ),
              key: 'price',
              dataIndex: 'price',
              width: 150,
              uniqueIndex: 'price',
              index: 14,
              visible: visibleFilters,
              render: (price) => (price ? priceFormatter(price) : 0),
            },
          ],
        },
      ],
    },
    {
      title: 'Единицы',
      uniqueIndex: 'unitsGroup',
      isGroup: true,
      columnId: 'active',
      disableResize: true,
      index: 3,
      children: [
        {
          title: 'Кол-во',
          key: 'count_services',
          dataIndex: 'count_services',
          uniqueIndex: 'count_services',
          width: 80,
          index: 16,
          children: [
            {
              title: (
                <RangeNumberFilters
                  confirm={(filtersTable) => handleTableChange(filtersTable)}
                  tableFilters={columnFiltersFromTableComponentRef.current}
                  selectedKeys={
                    columnFiltersFromTableComponentRef.current
                      ?.count_services__range
                  }
                  columnKey={'count_services__range'}
                />
              ),
              key: 'count_services',
              dataIndex: 'count_services',
              uniqueIndex: 'count_services',
              index: 16,
              width: 80,
              visible: visibleFilters,
            },
          ],
        },
        {
          title: 'Ед. изм.',
          dataIndex: 'serviceUnits',
          key: 'service',
          uniqueIndex: 'serviceUnits',
          width: 150,
          index: 18,
          render: (service) => (service ? service.units : ''),
          children: [
            {
              title: (
                <SearchDataFilter
                  inputPlaceholder={'Ед. изм.'}
                  confirm={(filtersTable) => handleTableChange(filtersTable)}
                  tableFilters={columnFiltersFromTableComponentRef.current}
                  selectedKeys={
                    columnFiltersFromTableComponentRef.current
                      ?.service__name__icontains
                  }
                  columnKey={'service__name__icontains'}
                />
              ),
              dataIndex: 'service',
              key: 'service',
              uniqueIndex: 'serviceUnits',
              index: 18,
              width: 150,
              visible: visibleFilters,
              render: (service) => (service ? service.units : ''),
            },
          ],
        },
      ],
    },
    {
      title: 'НДС',
      uniqueIndex: 'vatGroup',
      isGroup: true,
      columnId: 'active',
      disableResize: true,
      index: 4,
      children: [
        {
          title: 'Ставка',
          dataIndex: 'var_rate_str',
          key: 'var_rate_str',
          uniqueIndex: 'var_rate_str',
          width: 150,
          index: 20,
          children: [
            {
              title: (
                <FilterSelect
                  confirm={(filtersTable) => handleTableChange(filtersTable)}
                  tableFilters={columnFiltersFromTableComponentRef.current}
                  selectedKeys={
                    columnFiltersFromTableComponentRef.current?.var_rate
                  }
                  columnKey={'var_rate'}
                  dataList={[
                    { title: 'Без НДС', value: 'null' },
                    { title: '0%', value: 0 },
                    { title: '20', value: 20 },
                  ]}
                />
              ),
              dataIndex: 'var_rate_str',
              key: 'vat_rate_str',
              uniqueIndex: 'vat_rate_str',
              index: 20,
              width: 150,
              visible: visibleFilters,
            },
          ],
        },
        {
          title: 'Валюта(У)',
          key: 'sum_vat',
          dataIndex: 'sum_vat',
          uniqueIndex: 'sum_vat',
          width: 150,
          index: 22,
          children: [
            {
              title: (
                <RangeNumberFilters
                  confirm={(filtersTable) => handleTableChange(filtersTable)}
                  tableFilters={columnFiltersFromTableComponentRef.current}
                  selectedKeys={
                    columnFiltersFromTableComponentRef.current?.sum_vat__range
                  }
                  columnKey={'sum_vat__range'}
                />
              ),
              key: 'sum_vat',
              dataIndex: 'sum_vat',
              uniqueIndex: 'sum_vat',
              index: 22,
              width: 150,
              visible: visibleFilters,
              render: (sum) => (sum ? priceFormatter(sum) : 0),
            },
          ],
        },
      ],
    },
    {
      title: 'Всего',
      uniqueIndex: 'sumGroup',
      isGroup: true,
      columnId: 'active',
      disableResize: true,
      index: 5,
      children: [
        {
          title: 'Всего',
          key: 'sum',
          width: 150,
          uniqueIndex: 'sum_currency',
          index: 24,
          fixed: 'right',
          render: (data) =>
            data?.sum ? priceFormatter(data?.sum) : 'Не определено',
          children: [
            {
              title: (
                <RangeNumberFilters
                  confirm={(filtersTable) => handleTableChange(filtersTable)}
                  tableFilters={columnFiltersFromTableComponentRef.current}
                  selectedKeys={
                    columnFiltersFromTableComponentRef.current
                      ?.sum_currency__range
                  }
                  columnKey={'sum_currency__range'}
                />
              ),
              key: 'sum',
              width: 150,
              uniqueIndex: 'sum_currency',
              index: 24,
              visible: visibleFilters,
              render: (data) =>
                data?.sum ? priceFormatter(data?.sum) : 'Не определено',
            },
          ],
        },
        {
          title: 'Всего(У)',
          key: 'sum_with_vat',
          dataIndex: 'sum_with_vat',
          width: 150,
          uniqueIndex: 'sum_with_vat',
          index: 26,
          fixed: 'right',
          render: (sum) => (sum ? priceFormatter(sum) : 0),
          children: [
            {
              title: (
                <RangeNumberFilters
                  confirm={(filtersTable) => handleTableChange(filtersTable)}
                  tableFilters={columnFiltersFromTableComponentRef.current}
                  selectedKeys={
                    columnFiltersFromTableComponentRef.current
                      ?.sum_with_vat__range
                  }
                  columnKey={'sum_with_vat__range'}
                />
              ),
              key: 'sum_with_vat',
              dataIndex: 'sum_with_vat',
              width: 150,
              uniqueIndex: 'sum_with_vat',
              index: 26,
              visible: visibleFilters,
              render: (sum) => (sum ? priceFormatter(sum) : 0),
            },
          ],
        },
      ],
    },
  ];

  const handleResize = useCallback(
    (index) =>
      async (e, { size, uniqueIndex, isGroupItem }) => {
        let databaseColumnsId = null;
        const copyColumns = [...baseColumnsRef.current];

        if (!isGroupItem) {
          setBaseColumns((columns) => {
            return changeSizeColumn(columns, index, size);
          });

          databaseColumnsId = copyColumns[index]?.idInDatabase;
        } else {
          setBaseColumns((columns) => {
            const { nextColumns, idInDatabase } =
              changeColumnSizeInColumnsGroup(columns, index, uniqueIndex, size);
            databaseColumnsId = idInDatabase;
            return nextColumns;
          });
        }

        if (!databaseColumnsId) return;

        await editTableColumn(databaseColumnsId, { width: size }, userInfo);
        const res = await getProfileInfo(userInfo);
        if (res) {
          userInfo.current = res;
          const haveViewBranch = userInfoHaveViewAllBranch(res);
          if (haveViewBranch) {
            userInfo.current['haveViewBranch'] = true;
          }
        }
      },
    [baseColumnsRef.current],
  );

  const filteringColumnsChildrenPillarByVisible = useMemo(() => {
    const copyColumns = [...baseColumns];
    return filterActiveColumns(copyColumns);
  }, [baseColumns]);

  console.log('CustomerBillTable data:', dataList);

  return (
    <>
      {!isMobile ? (
        <Table
          scroll={{ x: 1000 }}
          showHeader
          bordered={true}
          dataSource={dataList}
          columns={filteringColumnsChildrenPillarByVisible}
          onRow={(cost) => {
            return {
              onDoubleClick: () => {
                handleEditConst(cost);
              },
            };
          }}
          components={{
            header: {
              cell: ResizableTableColumnHeader,
            },
          }}
          loading={loading}
          rowClassName={(record, index) =>
            index % 2 === 0
              ? 'black-text table-row-color'
              : 'black-text table-row-white'
          }
          size="small"
          pagination={{
            position: ['none'],
            size: 'middle',
            showSizeChanger: false,
          }}
        />
      ) : (
        <>
          <div style={{ paddingTop: '5px' }}>
            <ErrorBoundary>
              <ScrollingLoadedList
                dataList={dataList}
                totalData={dataList?.length}
                loadMoreItems={async () => {
                  //handleUpdate(page + 1, filters, false,true)
                }}
                renderListItem={(item) => (
                  <CostListItem
                    cost={item}
                    menuActions={(manager) => menu(manager)}
                    handleRenderCurrency={handleRenderCurrency}
                    priceFormatter={priceFormatter}
                  />
                )}
              />
            </ErrorBoundary>
          </div>
        </>
      )}
      <ErrorBoundary>
        <CustomerBillsModal
          visible={visibleModal}
          editingCost={editingCost}
          taskData={taskData}
          closeModal={async () => await handleCloseModal()}
          refreshTaskInfo={() => {
            refreshTaskInfo();
          }}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <UniversalFiltersModal
          visibleModal={visibleFilters && isMobile}
          handleClose={() => {
            setVisibleFilters(false);
          }}
          resetFilters={async () => {
            setVisibleFilters(false);
            await handleUpdateList(null);
          }}
          columnsList={convertingColumnsWithGroupsIntoRegularOnesForUseInMobileFilters(
            columns,
          )}
        />
      </ErrorBoundary>
    </>
  );
};

export default CustomerBillsTable;

CustomerBillsTable.propTypes = {
  dataList: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  taskData: PropTypes.object.isRequired,
  visibleFilters: PropTypes.bool,
  tableFilters: PropTypes.any,
  handleUpdateList: PropTypes.func.isRequired,
  refreshTaskInfo: PropTypes.func.isRequired,
};
