import { useCallback, useState } from 'react';
import { call } from '../apiUtils/call';
import { message } from 'antd';
import { restApiHost } from '../utils/appVariables';

interface Currency {
  id: number;
  signature: string;
  name: string;
  eng_name: string;
  sign: string;
  sign_template: string;
}

interface SearchParams {
  signature?: string;
  [key: string]: string | undefined;
}

const useCurrencySearch = () => {
  const [loading, setLoading] = useState(false);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [error, setError] = useState<string | null>(null);

  const searchCurrencies = useCallback(async (params: SearchParams) => {
    try {
      setLoading(true);
      setError(null);

      const url = params.id
        ? `${restApiHost}/refbook/currency/${params.id}/`
        : `${restApiHost}/refbook/currency/?${new URLSearchParams(
            Object.entries(params).reduce((acc, [key, value]) => {
              if (value) acc[key] = value;
              return acc;
            }, {} as Record<string, string>),
          ).toString()}`;

      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        true,
        null,
      );

      if (!json?.data) {
        message.error('Ошибка получения валют');
        return;
      }

      setCurrencies(Array.isArray(json.data) ? json.data : [json.data]);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Произошла ошибка');
      message.error('Ошибка при поиске валют');
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    searchCurrencies,
    currencies,
    loadingCurrencies: loading,
    currenciesError: error,
  };
};

export default useCurrencySearch;
