import PropTypes from 'prop-types';
import { Button, Form, message, Modal } from 'antd';
import { useCallback, useEffect, useContext } from 'react';
import { addTaskTemplateOperationToService } from '../../../../../../../logic/refbook/templateOperations/TemplateOperationsApiFuctions';
import { userInformations } from '../../../../../../../contex';
import TemplateOperationServicesModalForm from './TemplateOperationServicesModalForm';

const TemplateOperationServicesModal = (props) => {
  const { userInfo } = useContext(userInformations);
  const [form] = Form.useForm();
  const isEdtiting = !!props.editingOperation?.operation?.id;

  useEffect(() => {
    if (props.editingOperation) {
      form.setFieldsValue({
        operation: props.editingOperation.operation?.id,
      });
    }
  }, [props.editingOperation]);
  const handleCloseModal = useCallback(() => {
    props.closeModal && props.closeModal();
  }, [props.closeModal]);

  const handleCreateOrEditOperation = useCallback(() => {
    form
      .validateFields()
      .then(async (values) => {
        console.log('values', values);
        // const body = {
        //   ...values,
        //   // TODO: тут можно либо получать название операции
        //   // либо на бекенд надо добавить поддержку подобных запросов на создание операций без указания waypoint_name
        //   waypoint_name: 'waypoint_name',
        //   index: isEdtiting
        //     ? props.editingOperation.id
        //     : props.operationsListLength + 1,
        // };

        // if (!isEdtiting) {
        //   body.template = props.templateId;
        //   console.log('Привязываем услугу к операции...', body);
        // } else {
        //   console.log('Редактирование связи услуги-операции...', body);
        // }
        // const res = isEdtiting
        //   ? await editTaskTemplateOperation(
        //       props.editingOperation.id,
        //       body,
        //       userInfo,
        //     )
        //   : await addTaskTemplateOperationToService(values, userInfo);
        // const res = await addTaskTemplateOperationToService(values, userInfo);
        const res = await addTaskTemplateOperationToService(
          values.operation,
          values.service,
          userInfo,
        );

        if (res) {
          message.success(
            isEdtiting
              ? 'Услуга операции успешно отредактирована'
              : 'Услуга операции успешно добавлена',
          );
          form.resetFields();
          handleCloseModal();
        }
      })
      .catch((errorInfo) => {
        message.error('Пожалуйста, заполните все обязательные поля');
        console.log('Ошибка валидации:', errorInfo);
      });
  }, [form, props.editingOperation, props.operationsListLength]);

  // Удаление связи услуги-операции пока не реализовано. Функция не используется
  // const handleDeleteOperation = useCallback(() => {
  //   Modal.confirm({
  //     title: 'Подтверждение удаления',
  //     content: 'Вы действительно хотите удалить эту операцию?',
  //     okText: 'Да, удалить',
  //     cancelText: 'Отмена',
  //     okButtonProps: { danger: true },
  //     onOk: async () => {
  //       const success = await deleteTaskTemplateOperation(
  //         props.editingOperation.id,
  //         userInfo,
  //       );

  //       if (success) {
  //         message.success('Операция успешно удалена');
  //         handleCloseModal();

  //         props.onOperationDeleted &&
  //           props.onOperationDeleted(props.editingOperation.id);
  //       }
  //     },
  //   });
  // }, [props.editingOperation, userInfo]);

  return (
    <>
      <Modal
        visible={props.visible}
        destroyOnClose={true}
        zIndex={1000}
        title={
          isEdtiting
            ? 'Редактировать услугу операции'
            : 'Добавить услугу к операции'
        }
        onOk={handleCloseModal}
        onCancel={handleCloseModal}
        footer={[
          // props.editingOperation && (
          //   <Button key="delete" danger onClick={handleDeleteOperation}>
          //     Удалить
          //   </Button>
          // ),
          <Button key="back" onClick={handleCloseModal}>
            Закрыть
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleCreateOrEditOperation}
          >
            Сохранить
          </Button>,
        ].filter(Boolean)}
      >
        <TemplateOperationServicesModalForm
          form={form}
          isEdtiting={isEdtiting}
          editingOperation={props.editingOperation}
          operations={props.operations}
        />
      </Modal>
    </>
  );
};

export default TemplateOperationServicesModal;

TemplateOperationServicesModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  editingOperation: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  templateId: PropTypes.number,
  operationsListLength: PropTypes.number.isRequired,
  closeModal: PropTypes.func,
  // onOperationDeleted: PropTypes.func,
  operations: PropTypes.oneOfType([PropTypes.array, PropTypes.undefined]),
};
