import { Modal, Spin } from 'antd';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import TaskTemplateModalTabs from './componetns/tabs/TaskTemplateModalTabs';

const RefbookTaskTemplateModal = (props) => {
  const [loading] = useState(false);

  const handleCancelModal = useCallback(() => {
    props.closeModal && props.closeModal();
  }, []);
  return (
    <>
      <Modal
        width={800}
        destroyOnClose={true}
        title={`${props.editItem ? 'Редактирование' : 'Добавление'}`}
        visible={props.visibleModal}
        onCancel={handleCancelModal}
        footer={[]}
      >
        <Spin spinning={loading}>
          <TaskTemplateModalTabs
            editingTemplate={props.editItem}
            form={props.form}
            formField={props.formField}
            getComponent={props.getComponent}
            submitForm={props.submitForm}
          />
        </Spin>
      </Modal>
    </>
  );
};

RefbookTaskTemplateModal.propTypes = {
  visibleModal: PropTypes.bool.isRequired,
  editItem: PropTypes.object,
  formField: PropTypes.array.isRequired,
  form: PropTypes.any.isRequired,
  getComponent: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default RefbookTaskTemplateModal;
