import { restApiHost } from '../../../utils/appVariables';
import { message } from 'antd';
import { call } from '../../../apiUtils/call';
/**
 * Получение списка услуг операции по id операции шаблона
 */
export async function getTaskTemplateOperationServices(operationId, userInfo) {
  if (!operationId) return;

  const urlParams = new URLSearchParams();
  urlParams.append('template_operation', operationId);

  try {
    const url = `${restApiHost}/base/template_operation_services/?${urlParams}`;
    console.log(`GET from sevices tab: ${url}`);

    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (!json?.data) return;

    console.log(`GET from sevices tab: ${url} ; RESPONSE: `, json.data);
    return json.data;
  } catch (e) {
    console.log('fetch get task template operation services', e);
    message.error('Ошибка запроса на получение услуг операции шаблона');
  }
}

/**
 * Добавление услуги к операции шаблона
 */
export async function addTaskTemplateOperationToService(
  templateOperation,
  service,
  userInfo,
) {
  try {
    const url = `${restApiHost}/base/template_operation_services/`;
    console.log(`POST: ${url}`);
    console.log('REQUEST:', { templateOperation, service });

    const json = await call(
      url,
      {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          template_operation: templateOperation,
          service: service,
        }),
      },
      userInfo.current.haveViewBranch,
    );

    if (!json?.data) return;

    console.log(`POST: ${url} ; RESPONSE: `, json.data);
    return json.data;
  } catch (e) {
    message.error('Ошибка при добавлении услуги к операции шаблона');
    throw e;
  }
}
