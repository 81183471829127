import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Tabs } from 'antd';
import useStateRef from 'react-usestateref';
import TemplateOperations from './operations/TemplateOperations';
import TemplateOperationServices from './services/TemplateOperationServices';
import BaseInformation from './baseInformation/BaseInformation';
import { ErrorBoundary } from '../../../../ErrorBoundary';

const { TabPane } = Tabs;

const TaskTemplateModalTabs = (props) => {
  const [, setCurrentTab, currentTabRef] = useStateRef(1);

  const disableNoBaseTabs = useMemo(() => {
    return !props.editingTemplate;
  }, [props.editingTemplate]);

  return (
    <>
      <Tabs
        destroyInactiveTabPane={true}
        className={'tabs-scroll'}
        centered={false}
        type="card"
        activeKey={currentTabRef.current.toString()}
        onChange={(key) => setCurrentTab(Number(key))}
      >
        <TabPane tab="Основаная информации" key="1">
          <ErrorBoundary>
            <BaseInformation
              editingItem={props.editingTemplate}
              fields={props.formField}
              form={props.form}
              getComponent={props.getComponent}
              submitForm={props.submitForm}
            />
          </ErrorBoundary>
        </TabPane>
        <TabPane tab="Операции" disabled={disableNoBaseTabs} key="2">
          <ErrorBoundary>
            <TemplateOperations templateId={props.editingTemplate?.id} />
          </ErrorBoundary>
        </TabPane>
        <TabPane tab="Услуги" disabled={disableNoBaseTabs} key="3">
          <ErrorBoundary>
            <TemplateOperationServices templateId={props.editingTemplate?.id} />
          </ErrorBoundary>
        </TabPane>
      </Tabs>
    </>
  );
};

TaskTemplateModalTabs.propTypes = {
  editingTemplate: PropTypes.object.isRequired,
  formField: PropTypes.array.isRequired,
  form: PropTypes.any.isRequired,
  getComponent: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
};
export default TaskTemplateModalTabs;
