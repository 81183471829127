import PropTypes from 'prop-types';
import useCheckMobileScreen from '../../../../../../../../hooks/useCheckMobileScreen';
import useUserInfo from '../../../../../../../../hooks/useUserInfo';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Form, Input, InputNumber, Row, Select } from 'antd';
import { getListOperationWaypoint } from '../../../../../../../../logic/refbook/operationWaypoint/OperationWaypointApiFunctions';
import useAsyncEffect from 'use-async-effect';
import { getListService } from '../../../../../../../../logic/refbook/service/ServiceApiFunction';
import {
  getShippingTaskTransportationList,
  getAllCurrencyRates,
} from '../../../../../../../../logic/task/TaskApiFunctions';

const { Option } = Select;

const CustomerBillsModalForm = (props) => {
  const isMobile = useCheckMobileScreen();
  const userInfo = useUserInfo();
  const { form, item, visibleModal, taskData } = props;
  const [currency, setCurrency] = useState(item?.currency ?? 129);
  const isRubleSelected = currency === 129;
  const [currencyRates, setCurrencyRates] = useState({
    129: 1.0, // Курс рубля к рублю известен по умолчанию - 1
  });

  //console.log('item in form', item);
  // console.log('visibleModal', visibleModal);
  //console.log('taskData render', taskData);

  const [servicesOptions, setServicesOptions] = useState([]);
  const [operationsOptions, setOperationsOptions] = useState([]);
  const [taskTransportList, setTaskTransportList] = useState([]);
  const [loadingDefaultTransport, setLoadingDefaultTransport] = useState(false);

  // Получаем курсы валют при первой загрузке
  useAsyncEffect(async () => {
    try {
      const rates = await getAllCurrencyRates(userInfo);
      if (rates) {
        setCurrencyRates(rates);
        // Если это новая запись, устанавливаем курс по умолчанию
        if (!item?.currency_rate) {
          form.setFieldsValue({
            currency_rate: rates[currency]?.toFixed(2) || '1.00',
          });
        }
      }
    } catch (e) {
      console.log('Error loading currency rates:', e);
    }
  }, []);

  // Обновляем обработчик изменения валюты
  const handleCurrencyChange = (value) => {
    setCurrency(value);
    form.setFieldsValue({
      currency: value,
      currency_rate: currencyRates[value]?.toFixed(2) || '1.00',
    });
  };

  useAsyncEffect(async () => {
    await handleGetOperationsList();
    await handleGetServicesList();
  }, []);

  async function handleSetDefaultTransportField() {
    try {
      setLoadingDefaultTransport(true);
      const transport = form.getFieldsValue(['transport']);
      const selectedTransportId = transport.transport;
      const shouldSetDefaultTransport = !selectedTransportId;
      const transportList = await handleGetTaskTransportList();
      if (!transportList?.length) {
        console.log(
          'CustomerBillsModalForm.Error: Failed to load default transport list',
        );
        return;
      }

      if (!shouldSetDefaultTransport) return;
      form.setFieldsValue({
        transport: transportList[0]?.id ?? null,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingDefaultTransport(false);
    }
  }

  useAsyncEffect(async () => {
    if (visibleModal) {
      await handleSetDefaultTransportField();
    }
  }, [visibleModal, item]);

  useEffect(() => {
    if (item?.service) handleGetServicesList(item.service?.name);

    // устанавливаем Валюту по умолчанию - рубли
    if (!item?.currency) {
      form.setFieldsValue({
        currency: 129,
        currency_rate: '1.00',
      });
    }

    // Устанавливаем курс из полученных данных, если есть
    if (!item?.currency_rate && currency !== 129) {
      const rate = currencyRates[currency]?.toFixed(2) || '1.00';
      handleSetDefaultValue('currency_rate', rate);
    }

    // устанавливаем Кол.ед по умолчанию - 1
    if (!item?.count_services) {
      form.setFieldsValue({
        count_services: 1,
      });
    }

    if (!item?.price) {
      handleSetDefaultValue('price', '0.00');
    }
  }, [item, currencyRates]);

  const handleGetOperationsList = useCallback(
    async (keyword = null) => {
      const res = await getListOperationWaypoint(userInfo, keyword);
      if (res?.data) {
        setOperationsOptions(res.data);
      }
    },
    [setOperationsOptions],
  );

  const handleGetServicesList = useCallback(
    async (keyword = null) => {
      const res = await getListService(userInfo, keyword);
      if (res?.data) {
        setServicesOptions(res.data);
      }
    },
    [setServicesOptions],
  );

  const handleGetTaskTransportList = useCallback(async () => {
    if (!taskData?.id) return;
    const res = await getShippingTaskTransportationList(
      taskData?.id,
      null,
      userInfo,
    );
    if (res) {
      setTaskTransportList(res);
      return res;
    }
  }, [taskData, setTaskTransportList]);

  const handleSetDefaultValue = useCallback(
    (fieldName, val) => {
      if (!fieldName) return;
      const value = form.getFieldsValue([`${fieldName}`]);
      if (!value[`${fieldName}`]?.trim()) {
        form.setFieldsValue({
          [`${fieldName}`]: val,
        });
      }
    },
    [form],
  );

  const handleFocusPriceField = useCallback(
    (fieldName) => {
      if (!fieldName) return;
      const value = form.getFieldsValue([`${fieldName}`]);
      if (value[`${fieldName}`] === '0.00') {
        form.setFieldsValue({
          [`${fieldName}`]: '',
        });
      }
    },
    [form],
  );

  return (
    <>
      <Form form={form} layout={'vertical'}>
        <Row gutter={16}>
          <Col xs={24} lg={12}>
            <Form.Item name={'operation'} label={'Операция'}>
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
                showSearch
                filterOption={false}
                placeholder="Поиск операции"
                onSearch={async (value) => {
                  await handleGetOperationsList(value);
                }}
              >
                {operationsOptions && operationsOptions.length > 0 ? (
                  <>
                    {operationsOptions.map((item) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </>
                ) : (
                  <Option disabled value="Загрузка">
                    Не найдено
                  </Option>
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name={'service'}
              rules={[{ required: true, message: 'Обязательное поле!' }]}
              label={'Поиск услуги'}
            >
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
                showSearch
                //loading={searchLoading}
                filterOption={false}
                onSearch={async (value) => await handleGetServicesList(value)}
                placeholder="Найти услугу"
              >
                {servicesOptions.length > 0 ? (
                  <>
                    {Array.isArray(servicesOptions) &&
                      servicesOptions.map((item) => {
                        return (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                  </>
                ) : null}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              rules={[{ required: true, message: 'Обязательное поле!' }]}
              name={'transport'}
              label={'Транспорт'}
            >
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
                loading={loadingDefaultTransport}
                placeholder={'Выберите транспорт с заявки'}
              >
                {taskTransportList?.length > 0 ? (
                  taskTransportList.map((item, index) => {
                    return (
                      <Option key={index} value={item.id}>
                        {item?.transport?.transport_unit?.type_transport}
                      </Option>
                    );
                  })
                ) : (
                  <Option disabled value={null}>
                    Нет доступных вариантов
                  </Option>
                )}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item name={'currency'} label={'Валюта'}>
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
                onChange={handleCurrencyChange}
                value={currency}
              >
                <Option value={129}>Рубли</Option>
                <Option value={159}>Доллар</Option>
                <Option value={51}>Евро</Option>
                <Option value={35}>Юань</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={6}>
            <Form.Item
              disabled={isRubleSelected}
              name={'currency_rate'}
              label={'Курс'}
            >
              <Input
                disabled={isRubleSelected}
                placeholder={'Введите курс валюты'}
                onFocus={() => {
                  handleFocusPriceField('currency_rate');
                }}
                onBlur={() => {
                  handleSetDefaultValue(
                    'currency_rate',
                    currencyRates[currency]?.toFixed(2) || '1.00',
                  );
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={6}>
            <Form.Item name={'price'} label={'Цена за ед.'}>
              <Input
                placeholder={'Введите цену за ед.'}
                onFocus={() => {
                  handleFocusPriceField('price');
                }}
                onBlur={() => {
                  handleSetDefaultValue('price', '0.00');
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={6}>
            <Form.Item name={'vat_rate'} label={'НДС'}>
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
                placeholder={'НДС'}
              >
                <Option value={null}>Без НДС</Option>
                <Option value={0}>0%</Option>
                <Option value={20}>20%</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={6}>
            <Form.Item name={'count_services'} label={'Кол.ед'}>
              <InputNumber min={1} placeholder={'Кол.ед'} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CustomerBillsModalForm;

CustomerBillsModalForm.propTypes = {
  form: PropTypes.any.isRequired,
  item: PropTypes.object,
  visibleModal: PropTypes.bool.isRequired,
  taskData: PropTypes.object.isRequired,
};
