import { userInformations } from '../contex';

import { useContext } from 'react';

interface UserGroup {
  id: number;
  impact_index: number;
  name: string;
}

interface Branch {
  id: number;
  name: string;
  company: number;
}

interface TableColumn {
  id: number;
  visible: boolean;
  data_index: string;
  index: number;
  width: number;
  group_index?: string | null;
}

interface TableColumns {
  branches: TableColumn[];
  contracts: Array<Record<string, unknown>>;
  customers: Array<Record<string, unknown>>;
  leads: Array<Record<string, unknown>>;
}

interface UserInfoCurrent {
  abonent: null;
  all_sound: boolean;
  auth_pass_mail: boolean;
  branch: Branch;
  chat_notifications: boolean;
  company: number;
  date_joined: string;
  email: string;
  firebase_device_token: boolean;
  full_name: string;
  groups: UserGroup[];
  id: number;
  is_active: boolean;
  is_privileged: boolean;
  is_staff: boolean;
  permissions: string[];
  phone_number: string;
  sections: Array<Record<string, unknown>>;
  signature: null;
  table_columns: TableColumns;
}

interface UserInfo {
  current: UserInfoCurrent;
}

type UserInfoContext = UserInfo | null;

const useUserInfo = (): UserInfoContext => {
  // @ts-expect-error Контекст не типизирован, но его значение будет UserInfo или null
  const { userInfo } = useContext(userInformations);
  return userInfo;
};

export default useUserInfo;
