import PropTypes from 'prop-types';
import { Button, Col, Form, Row, Spin } from 'antd';
import React, { useCallback } from 'react';

const BaseInformation = (props) => {
  const submit = useCallback(
    async (values, editItem, deleteItem) => {
      const body = { ...values };
      if (!editItem) {
        await props.submitForm(body, null, null, true);
        return;
      }

      if (editItem && deleteItem) {
        await props.submitForm(body, editItem, deleteItem);
        return;
      }

      await props.submitForm(body, editItem, null, true);
    },
    [props.submitForm],
  );

  return (
    <>
      <Spin spinning={false}>
        <div style={{ padding: '10px' }}>
          <Form
            layout={'vertical'}
            form={props.form}
            onFinish={async (values) => {
              await submit(values, props.editingItem, false);
            }}
          >
            <Row gutter={16}>
              {props.fields?.length > 0 &&
                props.fields.map((item) => {
                  return props.getComponent(item[1]?.type, item);
                })}
            </Row>
            <Row justify={'end'} gutter={10}>
              <Col>
                <Button type={'primary'} htmlType={'submit'}>
                  {props.editingItem ? 'Сохранить' : 'Добавить'}
                </Button>
              </Col>
              <Col style={{ marginLeft: '10px' }}>
                {props.editingItem ? (
                  <Button
                    onClick={async () => {
                      await submit(null, props.editingItem, true);
                    }}
                  >
                    {'Удалить'}
                  </Button>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Form>
        </div>
      </Spin>
    </>
  );
};

export default BaseInformation;

BaseInformation.propTypes = {
  editingItem: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  form: PropTypes.any.isRequired,
  submitForm: PropTypes.func.isRequired,
  getComponent: PropTypes.func.isRequired,
};
