import PropTypes from 'prop-types';
import { Button, Space, Tooltip } from 'antd';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { ErrorBoundary } from '../../../../../ErrorBoundary';
import TemplateOperationsTable from './TemplateOperationsTable';
import { getTaskTemplateOperationsByTemplateId } from '../../../../../../logic/refbook/tasksTemplate/TaskTempalteApiFunctions';
import useAsyncEffect from 'use-async-effect';
import { userInformations } from '../../../../../../contex';
import TemplateOperationModal from './tempalteOperatiomModa/TemplateOperationModal';

const TemplateOperations = (props) => {
  const { userInfo } = useContext(userInformations);
  const [visibleModal, setVisibleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [operationDataList, setOperationDataList] = useState([]);

  useAsyncEffect(async () => {
    if (props.templateId) {
      await handleGetTemplateOperationList(props.templateId);
    }
  }, [props.templateId]);

  const handleAddOperation = useCallback(() => {
    setVisibleModal(true);
  }, [setVisibleModal]);

  const handleCloseModal = useCallback(async () => {
    setVisibleModal(false);
    await handleGetTemplateOperationList(props.templateId);
  }, [props.templateId]);

  const handleGetTemplateOperationList = useCallback(
    async (templateId) => {
      try {
        if (!templateId) return;
        setLoading(true);
        const res = await getTaskTemplateOperationsByTemplateId(
          templateId,
          userInfo,
        );
        if (!res) return;
        setOperationDataList(res);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [setLoading],
  );

  const dataListLength = useMemo(() => {
    return operationDataList?.length ?? 0;
  }, [operationDataList]);
  return (
    <>
      <div className={'top-table-actions'}>
        <Space>
          <Tooltip placement={'topLeft'} title={'Добавить'}>
            <Button
              size={'small'}
              icon={<PlusOutlined />}
              onClick={() => handleAddOperation()}
              type="primary"
            />
          </Tooltip>
        </Space>
      </div>
      <ErrorBoundary>
        <TemplateOperationsTable
          templateId={props.templateId}
          dataList={operationDataList}
          loading={loading}
          setOperationDataList={(newDataList) =>
            setOperationDataList(newDataList)
          }
          handleUpdateDataList={async () => {
            await handleGetTemplateOperationList(props.templateId);
          }}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <TemplateOperationModal
          visible={visibleModal}
          templateId={props.templateId}
          closeModal={() => handleCloseModal()}
          operationsListLength={dataListLength}
        />
      </ErrorBoundary>
    </>
  );
};

TemplateOperations.propTypes = {
  templateId: PropTypes.number,
};

export default TemplateOperations;
