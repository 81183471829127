import { restApiHost } from '../../../utils/appVariables';
import { message } from 'antd';
import { call } from '../../../apiUtils/call';

/**
 * Получение списка операций по id шаблона
 */
export async function getTaskTemplateOperationsByTemplateId(
  templateId,
  userInfo,
) {
  if (!templateId) return;

  const urlParams = new URLSearchParams();

  urlParams.append('template', templateId);
  try {
    const url = `${restApiHost}/base/template_operation/?${urlParams}`;
    console.log(`GET: ${url}`);

    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (!json?.data) return;

    console.log(`GET: ${url} ; RESPONSE: `, json.data);
    return json.data;
  } catch (e) {
    console.log('fetch get task template operations by template id', e);
    message.error('Ошибка запроса на получение операций по шаблону');
  }
}

/**
 * Добавление операции к шаблону
 */
export async function addTaskTemplateOperation(body, userInfo) {
  try {
    const url = `${restApiHost}/base/template_operation/`;
    const json = await call(
      url,
      {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo.current.haveViewBranch,
    );

    if (!json?.data) return;

    return json.data;
  } catch (e) {
    console.log('fetch add task template operation', e);
    message.error('Ошибка запроса на добавление операций к шаблону');
  }
}

/**
 * Редактирование операции у шаблона
 */
export async function editTaskTemplateOperation(id, body, userInfo) {
  try {
    console.log('editTaskTemplateOperation вызван...');
    const url = `${restApiHost}/base/template_operation/${id}/`;

    console.log(`PATCH: ${url} BODY:`, body);

    console.log('editTaskTemplateOperation вызывает call...');
    const json = await call(
      url,
      {
        method: 'PATCH',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo.current.haveViewBranch,
    );

    console.log(`PATCH: ${url} ; RESPONSE: `, json.data);
    if (!json?.data) return;

    return json.data;
  } catch (e) {
    console.log('fetch edit task template operation', e);
    message.error('Ошибка запроса на редактирование операции шаблона');
  }
}

/**
 * Удаление операции шаблона
 */
export async function deleteTaskTemplateOperation(id, userInfo) {
  try {
    const url = `${restApiHost}/base/template_operation/${id}/`;

    const json = await call(
      url,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );
    if (!json?.data) return false;
    return true;
  } catch (e) {
    console.log('fetch delete task template operation', e);
    message.error('Ошибка запроса на удаление операции шаблона');
    return false;
  }
}
