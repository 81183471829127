import PropTypes from 'prop-types';
import { Button, Form, message, Modal } from 'antd';
import { ErrorBoundary } from '../../../../../../../ErrorBoundary';
import React, {
  useCallback,
  //useContext,
  useEffect,
  useState,
} from 'react';

// import {
//   addShippingTaskCost,
//   editShippingTaskCost,
// } from '../../../../../../../../logic/task/TaskApiFunctions';
// import { userInformations } from '../../../../../../../../contex';
import CustomerBillsModalForm from './CustomerBillsModalForm';
import {
  addClientCost,
  editClientCost,
} from '../../../../../../../../logic/task/TaskApiFunctions';
import useUserInfo from '../../../../../../../../hooks/useUserInfo';

const CustomerBillsModal = (props) => {
  const { taskData, editingCost, closeModal, visible } = props;
  const isEditing = !!editingCost?.id;

  const userInfo = useUserInfo();
  const [form] = Form.useForm();
  const [inCurrency, setInCurrency] = useState(false);

  //const { userInfo } = useContext(userInformations);
  // console.log('editing cost in modal', editingCost);
  useEffect(() => {
    if (editingCost) {
      handleSetFormFieldsValue(editingCost);
    }
  }, [editingCost]);

  const handleSetFormFieldsValue = (editingCost) => {
    if (!editingCost) return;
    form.setFieldsValue({
      ...editingCost,
      transport: editingCost?.transport,
      in_currency: editingCost?.in_currency,
      customer: editingCost?.customer?.id || null,
      operation: editingCost?.operation?.id || null,
      service: editingCost?.service?.id ?? null,
    });
  };

  const handleCloseModal = useCallback(() => {
    form.resetFields();
    closeModal && closeModal();
  }, [closeModal]);

  const handleCreateOrEditCost = useCallback(() => {
    form
      .validateFields()
      .then(async (values) => {
        let body = {
          ...values,
          in_currency: inCurrency,
          task: taskData?.id,
        };
        const res = isEditing
          ? await editClientCost(editingCost?.id, body, userInfo)
          : await addClientCost(body, userInfo);

        if (res) {
          message.success(
            isEditing
              ? 'Сумма клиенту успешно отредактирована'
              : 'Сумма клиенту успешно добавлена',
          );
          handleCloseModal();
          //refreshTaskInfo();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [form, inCurrency, editingCost]);

  return (
    <>
      <Modal
        visible={visible}
        destroyOnClose={true}
        zIndex={199}
        title={
          isEditing ? 'Редактировать сумму клиенту' : 'Добавить сумму клиенту'
        }
        onOk={() => {
          handleCloseModal();
        }}
        onCancel={() => {
          handleCloseModal();
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              handleCloseModal();
            }}
          >
            Закрыть
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={async () => {
              await handleCreateOrEditCost();
            }}
          >
            Сохранить
          </Button>,
        ]}
      >
        <ErrorBoundary>
          <CustomerBillsModalForm
            form={form}
            item={editingCost}
            visibleModal={visible}
            taskData={taskData}
            inCurrency={inCurrency}
            setInCurrency={(value) => {
              setInCurrency(value);
            }}
          />
        </ErrorBoundary>
      </Modal>
    </>
  );
};

export default CustomerBillsModal;

CustomerBillsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  taskData: PropTypes.object.isRequired,
  editingCost: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
};
