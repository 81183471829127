import PropTypes from 'prop-types';
import { Button, Space, Tooltip } from 'antd';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import { useCallback, useContext, useMemo, useState } from 'react';
import TemplateOperationServicesTable from './TemplateOperationServicesTable';
import useAsyncEffect from 'use-async-effect';
import TemplateOperationServicesModal from './tempalteOperationServicesModal/TemplateOperationServicesModal';
import { ErrorBoundary } from '../../../../../ErrorBoundary';
import { userInformations } from '../../../../../../contex';
import { getTaskTemplateOperationServices } from '../../../../../../logic/refbook/templateOperations/TemplateOperationsApiFuctions';
import { getTaskTemplateOperationsByTemplateId } from '../../../../../../logic/refbook/tasksTemplate/TaskTempalteApiFunctions';

// Установите true чтобы скрыть операции без услуг
const FILTER_OPERATIONS_WITHOUT_SERVICES = false;

const TemplateOperationServices = ({ templateId }) => {
  const { userInfo } = useContext(userInformations);
  const [visibleModal, setVisibleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [operationDataList, setOperationDataList] = useState([]);
  const canAddService = useMemo(() => {
    return operationDataList.some((item) => !item.service);
  }, [operationDataList]);

  // Иницилизируем таблицу при загрузке компонента
  useAsyncEffect(async () => {
    if (templateId) {
      await handleUpdateDataListAsync(templateId);
    }
  }, [templateId]);

  const handleAddOperation = useCallback(() => {
    setVisibleModal(true);
  }, [setVisibleModal]);

  const handleCloseModal = useCallback(async () => {
    setVisibleModal(false);
    await handleUpdateDataListAsync(templateId);
  }, [setVisibleModal, templateId]);

  const handleUpdateDataListAsync = useCallback(
    async (templateId) => {
      try {
        setLoading(true);
        const operations = await getTaskTemplateOperationsByTemplateId(
          templateId,
          userInfo,
        );

        if (operations) {
          const operationsWithServices = await Promise.all(
            operations.map(async (operation) => {
              const services = await getTaskTemplateOperationServices(
                operation.id,
                userInfo,
              );

              if (
                FILTER_OPERATIONS_WITHOUT_SERVICES &&
                !services?.[0]?.service
              ) {
                return null;
              }

              return {
                id: operation.id,
                operation: operation,
                service: services?.[0]?.service || null,
              };
            }),
          );

          const formattedList = operationsWithServices
            .filter(
              (item) => !FILTER_OPERATIONS_WITHOUT_SERVICES || Boolean(item),
            )
            .map((item, index) => ({
              ...item,
              index: index + 1,
            }));

          setOperationDataList(formattedList);
        }
      } catch (error) {
        console.error('Ошибка при обновлении списка услуг:', error);
        message.error('Ошибка при обновлении списка услуг');
      } finally {
        setLoading(false);
      }
    },
    [setLoading],
  );

  const dataListLength = useMemo(() => {
    return operationDataList?.length ?? 0;
  }, [operationDataList]);
  return (
    <>
      <div className={'top-table-actions'}>
        <Space>
          <Tooltip
            placement={'topLeft'}
            title={
              canAddService
                ? 'Добавить услугу к операции'
                : 'Нет операций без услуг'
            }
          >
            <Button
              size={'small'}
              icon={<PlusOutlined />}
              onClick={() => handleAddOperation()}
              type="primary"
              disabled={!canAddService}
            />
          </Tooltip>
        </Space>
      </div>
      <ErrorBoundary>
        <TemplateOperationServicesTable
          templateId={templateId}
          dataList={operationDataList}
          loading={loading}
          setOperationDataList={(newDataList) =>
            setOperationDataList(newDataList)
          }
          handleUpdateDataListAsync={async () => {
            await handleUpdateDataListAsync(templateId);
          }}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <TemplateOperationServicesModal
          visible={visibleModal}
          operations={operationDataList}
          templateId={templateId}
          closeModal={() => handleCloseModal()}
          operationsListLength={dataListLength}
        />
      </ErrorBoundary>
    </>
  );
};

TemplateOperationServices.propTypes = {
  templateId: PropTypes.number,
};

export default TemplateOperationServices;
